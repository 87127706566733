import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Flex, Box } from "@nulogy/components";
import { config } from "../../playgrounds/flex";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Flex`}</h1>
    <p>{`A wrapper component that extends `}<a parentName="p" {...{
        "href": "/box"
      }}>{`Box`}</a>{` to create layouts using Flexbox.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h3>{`Responsive`}</h3>
    <p>{`Like Box, all props can be used responsively by using an object that defines small, medium and/or large, based on the `}<a parentName="p" {...{
        "href": "https://nulogy.design/theme/"
      }}>{`theme.breakpoints`}</a>{` object`}</p>
    <p>{`This example displays columns on small screens and rows on large ones.`}</p>
    <Example componentName="flex" scope={{
      Flex,
      Box
    }} example="ResponsiveFlex" mdxType="Example" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/box"
        }}>{`Box`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      